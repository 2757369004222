import { TOAST_VARIANT } from '~/theme/default/alert-theme';

/**
 * @description A subclasses of Error with additional fields which may be passed on to a toast message.
 */
export class CustomError extends Error {
  /**
   *
   * @param {string} title
   * @param {string} description
   */
  constructor({ title, description }) {
    super();

    this.title = title;
    this.description = description;
  }
}

const DEFAULT_DESCRIPTION = 'Please try again';

/**
 *
 * @param {*} error
 * @returns {boolean}
 * @description Returns true if the argument is an instance of {@link CustomError}.
 */
export const isCustomError = (error) => error instanceof CustomError;

/**
 *
 * @param {func} toast The function obtained from Chakra UI for displaying toast messages
 * @param {CustomError} error The CustomError to handle
 * @param {string} titleArg Optional. A fallback title to use if one is not provided inside CustomError
 * @description A handler meant for errors of type {@link CustomError}.
 * Results in a custom toast message being rendered.
 */
export const handleCustomError = (toast, error, titleArg) => {
  let title = error.title || titleArg;
  let description = error.description || error.message || DEFAULT_DESCRIPTION;

  toast({
    title,
    description,
    variant: TOAST_VARIANT.ERROR,
    status: TOAST_VARIANT.ERROR,
  });
};
