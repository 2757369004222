import { Box, Divider, Stack, Text, useBreakpointValue, VStack } from '@chakra-ui/react';
import * as PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { LogoWhite } from '~/components/icons';
import { usePdfLocations } from '~/hooks/usePdfLocations';
import { PoweredByLogo } from '../icons/powered-by-logo';

const TERMS_TEXT =
  '©2024 Finfare, Inc. All rights reserved. Finfare Rewards is a registered trademark of Finfare, Inc. Third-party trademarks are the property of their respective third-party owners. Presence of a third-party trademark does not mean that Finfare has any relationship with that third-party or that the third-party endorses Finfare or its services.';

export default function Footer({ styles }) {
  const responsive = useBreakpointValue(
    {
      base: { alignSelf: 'center', flexDirection: 'column', spacing: '8px' },
      sm: { alignSelf: 'center', flexDirection: 'column', spacing: '8px' },
      md: { alignSelf: 'flex-end', flexDirection: 'row', spacing: '100px' },
    },
    { fallback: 'md', ssr: false }
  );

  const pdfLocations = usePdfLocations();

  return (
    <>
      <Divider />
      <Box layerStyle='footer' as='footer' data-role='footer-container' sx={styles} mt='auto'>
        <VStack data-role='footer-container-vertical' spacing={4} align='stretch' w='100%'>
          <Stack
            flexDirection={responsive.flexDirection}
            data-role='footer-logo'
            justify='space-between'
            align='center'
          >
            <Stack flexDirection={responsive.flexDirection}>
              <PoweredByLogo />
            </Stack>
            <Stack
              alignSelf={responsive.alignSelf}
              flexDirection={responsive.flexDirection}
              alignItems={responsive.alignSelf}
              spacing={responsive.spacing}
              data-role='footer-links'
              my={['12px', '12px', 0]}
            >
              <Link to={pdfLocations.ACCESSIBILITY} target='_blank'>
                <Text textStyle='footer-text'>Accessibility</Text>
              </Link>
              <Link to={pdfLocations.PRIVACY_POLICY} target='_blank'>
                <Text textStyle='footer-text'>Privacy Policy</Text>
              </Link>
              <Link to={pdfLocations.TERMS_AND_CONDITIONS} target='_blank'>
                <Text textStyle='footer-text'>User Terms and Conditions</Text>
              </Link>
              <Link to='/contact'>
                <Text textStyle='footer-text'>Contact Us</Text>
              </Link>
            </Stack>
          </Stack>
          <Text textStyle='footer-terms-text'>{TERMS_TEXT}</Text>
        </VStack>
      </Box>
    </>
  );
}

Footer.propTypes = { styles: PropTypes.object };
