import { isFunction } from 'lodash-es';
import { TOAST_VARIANT } from '~/theme/default/alert-theme';
import { USER_URL } from '../constants';
import { goUrl, responseStatusIsGood } from '../helpers';

/**
 * @description Extends Error. Accepts a Response in its constructor and prepares a descriptive message
 * based on its non-2XX or 3XX status.
 */
export class BadStatusError extends Error {
  /**
   * @param {Response} response
   */
  constructor(response) {
    super();

    this.response = response;
  }
}

/**
 *
 * @param {*} error
 * @returns {boolean}
 * @description Returns true if the argument is an instance of {@link BadStatusError}.
 */
export const isBadStatusError = (error) => error instanceof BadStatusError;

/* Start Descriptions */
const DEFAULT_DESCRIPTION = 'Please try again';

const badRequestDescription = 'Bad request, please check your request';

const unauthenticatedDescription = (response) => {
  if (response.url === `${USER_URL}refresh-token`) {
    return 'Unable to refresh auth token';
  }

  let description = `${
    response.url === `${USER_URL}refresh-token`
      ? 'Unable to refresh auth token'
      : 'User not authenticated'
  }`;

  return description;
};

const unauthorizedDescription = 'Unauthorized. You do not have access to this resource';

const internalServerDescription = 'Server Error. Please try again';
/* End Descriptions */

/* Start Handlers */
const handleUnauthenticated = () => {
  if (typeof localStorage !== 'undefined') {
    localStorage.clear();
  }
  if (typeof window !== 'undefined') {
    window.location.reload();
  }
};

const handleUnauthorized = (response, navigate) => {
  navigate('/brands');
};
/* End Handlers */

const descriptions = {
  400: badRequestDescription,
  401: unauthenticatedDescription,
  403: unauthorizedDescription,
  500: internalServerDescription,
};

const handlers = {
  401: handleUnauthenticated,
  403: handleUnauthorized,
};

// Exceptions to the server error message or default message on a per-endpoint basis
const overrides = {
  [`${USER_URL}signup`]: function (response, body) {
    if (typeof body?.message === 'string' && /account already exists/.test(body?.message)) {
      return {
        title: 'Cannot sign up',
        description:
          'You have already signed up. Click the "Sign In" button at the top right corner of the screen.',
      };
    }
  },
  [`${USER_URL}login`]: function (response, body) {
    if (typeof body?.message === 'string' && /Invalid user/.test(body?.message)) {
      return {
        title: 'Cannot sign in',
        description: 'Invalid user or password',
      };
    }
  },
};

/**
 * @param {func} toast The function obtained from Chakra UI for displaying toast messages
 * @param {BadStatusError} error The BadStatusError to handle
 * @param {string} title Optional. A fallback title to use if one is not provided inside CustomError
 * @description A customizable handler for all responses with non-successful status codes,
 * including reasonable default behaviors.
 */
export const handleBadStatusError = async (toast, error, title) => {
  const { response } = error;

  if (!response) {
    return;
  }

  if (responseStatusIsGood(response)) {
    console.warn(`Response with good status was passed to handleBadStatusError`);
    return;
  }

  const status = response.status;
  const body = await response.json();

  let description = body?.message || body?.root_cause;
  if (!description) {
    description = descriptions[status] ?? DEFAULT_DESCRIPTION;
    if (isFunction(description)) {
      description = await description(response);
    }
  }

  let handler = handlers[status];

  const { pathname } = new URL(response.url);
  if (overrides[pathname]) {
    const override = overrides[pathname](response, body);

    description = override?.description ?? description;
    title = override?.title ?? title;
    handler = override?.handler ?? handler;
  }

  toast({
    title,
    description,
    variant: TOAST_VARIANT.ERROR,
    status: TOAST_VARIANT.ERROR,
  });

  if (handler) {
    handler(response, goUrl);
  }
};
