import { merge } from 'lodash-es';
import { useCallback, useEffect, useRef, useState } from 'react';
import configFallback from '~/lib/config-fallback.json';
import { BadStatusError, handleError } from '~/lib/errors';
import { responseStatusIsGood } from '~/lib/helpers';

const ENVIRONMENT = import.meta.env.VITE_ENVIRONMENT ?? 'dev';

let cachedConfig;

export const useConfig = () => {
  const [config, setConfig] = useState(
    cachedConfig ?? {
      ...configFallback,
      isConfigLoaded: false,
      env: ENVIRONMENT,
    }
  );
  const isInitializingRef = useRef(false);

  const fetchConfig = useCallback(async () => {
    // unlikely an error will ever occur since we're "fetching" a local static file but just in case
    try {
      const response = await fetch('/config/config.json');

      if (!responseStatusIsGood(response)) {
        throw new BadStatusError(response);
      }

      const data = await response.json();

      const newConfig = {
        /* begin with configFallback as base; overwrite with fetched publisher config
             wherever properties are found; is recursive */
        ...merge(configFallback, data),
        isConfigLoaded: true,
        env: ENVIRONMENT,
      };
      cachedConfig = newConfig;
      setConfig(newConfig);
    } catch (err) {
      /* the one exception where pasing a toast argument is not possible due to this error
         blocking ChakraProvider from loading */
      handleError(null, err, 'Can not load publisher config');
    }
  }, []);

  const injectFavicon = useCallback((publisherConfig) => {
    const favicon = publisherConfig?.publisher?.favicon;

    if (!favicon || typeof window === 'undefined') {
      return;
    }

    let link = window.document.querySelector("link[rel*='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      window.document.getElementsByTagName('head')[0].appendChild(link);
    }

    // Set the new favicon path
    link.href = favicon;
  }, []);

  useEffect(() => {
    const initPublisherSettings = async () => {
      const publisherConfig = await fetchConfig();

      injectFavicon(publisherConfig);
    };

    if (!config?.isConfigLoaded) {
      if (isInitializingRef.current) {
        return;
      } else {
        isInitializingRef.current = true;

        initPublisherSettings();
      }
    }
  }, [config?.isConfigLoaded, fetchConfig, injectFavicon]);

  return cachedConfig ?? config;
};
