import { ModalBody, ModalCloseButton, ModalFooter, ModalHeader } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export const ModalLayout = ({
  header,
  headerProps = {},
  body,
  bodyProps = {},
  footer,
  footerProps = {},
}) => {
  return (
    <>
      <ModalCloseButton />
      {header && (
        <ModalHeader
          display='flex'
          flexDirection='column'
          rowGap={4}
          padding={0}
          margin={0}
          mb={4}
          {...headerProps}
        >
          {header}
        </ModalHeader>
      )}
      {body && (
        <ModalBody
          color='neutral.primary'
          fontWeight='medium'
          fontFamily='poppins'
          display='flex'
          flexDirection='column'
          alignItems='center'
          rowGap={4}
          padding={0}
          mb={8}
          flexShrink={1}
          overflowY='scroll'
          {...bodyProps}
        >
          {body}
        </ModalBody>
      )}
      {footer && (
        <ModalFooter display='flex' justifyContent='center' padding={0} {...footerProps}>
          {footer}
        </ModalFooter>
      )}
    </>
  );
};

ModalLayout.propTypes = {
  header: PropTypes.node,
  headerProps: PropTypes.object,
  body: PropTypes.node,
  bodyProps: PropTypes.object,
  footer: PropTypes.node,
  footerProps: PropTypes.object,
};
