import * as PropTypes from 'prop-types';
import { HelmetProvider } from 'react-helmet-async';
import { Chakra } from '~/components/Chakra';
import { AccountProvider } from '~/lib/state/contexts/AccountContext/AccountContext';
import AlertProvider from '~/lib/state/contexts/AlertContext';
import { AwsWafProvider } from '~/lib/state/contexts/AwsWafContext';
import FinanceProvider from '~/lib/state/contexts/FinanceContext';
import { ModalProvider } from '~/lib/state/contexts/ModalContext';
import { UkFinanceProvider } from '~/lib/state/contexts/UkFinanceContext';

export const Provider = ({ children }) => (
  <Chakra>
    <AwsWafProvider>
      <HelmetProvider>
        <AlertProvider>
          <AccountProvider>
            <FinanceProvider>
              <UkFinanceProvider>
                <ModalProvider>{children}</ModalProvider>
              </UkFinanceProvider>
            </FinanceProvider>
          </AccountProvider>
        </AlertProvider>
      </HelmetProvider>
    </AwsWafProvider>
  </Chakra>
);

Provider.propTypes = {
  children: PropTypes.node,
};
