import { asPx } from '~/lib/helpers';

export const IMAGE_CONTAIN = {
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'contain',
};
export const IMAGE_COVER = {
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
};

export const HEADER_ICON_HEIGHT = 32;
export const MAJOR_HEAD = {
  textAlign: 'center',
  fontSize: ['20px', '18px', '20px', '20px'],
  as: 'h1',
  fontWeight: 600,
  pb: 3,
};
export const MINOR_HEAD = {
  textAlign: 'center',
  fontSize: ['18px', '16px', '18px', '18px'],
  as: 'h2',
  fontWeight: 600,
  pb: 3,
};
export const OFFER_HEAD = {
  textAlign: 'center',
  fontSize: '2xl',
  pb: 2,
};
export const OFFER_BRAND = {
  textAlign: 'center',
  fontSize: 'sm',
  pt: 2,
};

export const CAROUSEL = {
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  borderRadius: '25px',
  backgroundColor: 'transparent',
};

export const PAGE_CONTAINER_MAX_WIDTH = '75rem'; // 1200px;
export const PAGE_CONTAINER_X_MARGIN = '1.5rem';
export const BRAND_BANNER_HEIGHT = '130px';

export const STORE_LOGO_SIZE_PX = '140px';
export const GIFT_CARD_STORE_LOGO_SIZE_PX = '80px';
export const BRAND_IMAGE_RADIUS = '12px';
export const BRAND_PADDING = '12px';

export const BRAND_PAGE_LOGO_HEIGHT = 170;
export const BRAND_PAGE_LOGO_PX = asPx(BRAND_PAGE_LOGO_HEIGHT);
export const BRAND_PAGE_BANNER_PX = '240px';
