import { useContext } from 'react';
import { ModalActionContext } from '~/lib/state/contexts/ModalContext';

export const useModalAction = () => {
  const modalActionValues = useContext(ModalActionContext);
  if (!modalActionValues) {
    throw new Error('useModalAction must be used within ModalProvider');
  }
  return modalActionValues;
};
