import {
  Button,
  FormControl,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import * as PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa6';
import * as Yup from 'yup';
import { ErrorRow } from '~/components/common/error-row';
import { useAccountState } from '~/hooks/account/useAccountState';
import { useTranslations } from '~/hooks/useTranslations';
import { useValidationSchema } from '~/hooks/useValidationSchema';
import { CHANGE_PASSWORD_URL } from '~/lib/constants';
import { handleError } from '~/lib/errors';
import { responseStatusIsGood } from '~/lib/helpers';
import { TOAST_VARIANT } from '~/theme/default/alert-theme';
import { settingsModalView } from './settings-modal-container';

const initialValues = {
  currentPassword: '',
  newPassword: '',
  confirmPassword: '',
};

export const ChangePasswordFormModal = ({ setView }) => {
  const toast = useToast();
  const { genericTranslation } = useTranslations();
  const translations = genericTranslation.settings.changePassword;
  const { changePasswordAccessToken } = useAccountState();
  const [submitting, setSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { passwordValidation, confirmPasswordValidation } = useValidationSchema();

  const validationSchema = Yup.object().shape({
    currentPassword: passwordValidation,
    newPassword: passwordValidation.notOneOf(
      [Yup.ref('currentPassword')],
      genericTranslation.validation.passwordIsNew
    ),
    confirmPassword: confirmPasswordValidation('newPassword'),
  });

  const onSubmit = useCallback(
    async (values) => {
      setSubmitting(true);

      try {
        const response = await fetch(CHANGE_PASSWORD_URL, {
          method: 'POST',
          body: JSON.stringify({
            previous_password: values.currentPassword,
            proposed_password: values.newPassword,
          }),
          headers: {
            Authorization: `Bearer ${changePasswordAccessToken}`,
            'Content-Type': 'application/json',
          },
        });

        if (!responseStatusIsGood(response)) {
          const body = await response.json();
          let description = body?.message || body?.root_cause || '';

          if (body?.status === 401) {
            description = translations.currentError;
          }

          toast({
            title: translations.errorMessage,
            description,
            variant: TOAST_VARIANT.ERROR,
            status: TOAST_VARIANT.ERROR,
          });
        } else {
          setView(settingsModalView.changePassword.success);
        }
      } catch (error) {
        handleError(toast, error, translations.errorMessage);
      } finally {
        setSubmitting(false);
      }
    },
    [
      toast,
      setSubmitting,
      setView,
      changePasswordAccessToken,
      translations.errorMessage,
      translations.currentError,
    ]
  );

  const { handleSubmit, values, handleBlur, handleChange, errors } = useFormik({
    validateOnMount: false,
    validationSchema,
    initialValues,
    onSubmit,
  });

  return (
    <>
      <ModalHeader display='flex' alignItems='center' flexDirection='column' pt={0} mb={0}>
        <Text
          as='h1'
          fontSize='lg'
          fontFamily='display'
          fontWeight='medium'
          color='neutral.primary'
        >
          {translations.header}
        </Text>
        <Text as='h2' fontSize='sm' fontFamily='body' color='secondary.primary' fontWeight='normal'>
          {translations.subHeader}
        </Text>
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody pt={0} display='flex' flexDirection='column' alignItems='flex-start' gap={4}>
        <VStack as='form' width='100%' spacing={8} onSubmit={handleSubmit}>
          <VStack width='100%' spacing={4}>
            <VStack width='100%'>
              <FormControl>
                <InputGroup>
                  <Input
                    name='currentPassword'
                    layerStyle='sign-in-field'
                    type={showPassword ? 'text' : 'password'}
                    value={values.currentPassword}
                    placeholder={translations.currentPlaceholder}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete='current-password'
                  />
                  <InputRightElement height='100%'>
                    <IconButton
                      variant='unstyled'
                      display='flex'
                      justifyContent='center'
                      alignItems='center'
                      onClick={() => setShowPassword((current) => !current)}
                      color='blackAlpha.500'
                      mr={1}
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </IconButton>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <ErrorRow isPresent={Boolean(errors.currentPassword)}>
                {errors.currentPassword}
              </ErrorRow>

              <FormControl mt={2}>
                <InputGroup>
                  <Input
                    name='newPassword'
                    layerStyle='sign-in-field'
                    type={showNewPassword ? 'text' : 'password'}
                    value={values.newPassword}
                    placeholder={translations.newPlaceholder}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete='new-password'
                  />
                  <InputRightElement height='100%'>
                    <IconButton
                      variant='unstyled'
                      display='flex'
                      justifyContent='center'
                      alignItems='center'
                      onClick={() => setShowNewPassword((current) => !current)}
                      color='blackAlpha.500'
                      mr={1}
                    >
                      {showNewPassword ? <FaEyeSlash /> : <FaEye />}
                    </IconButton>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <ErrorRow isPresent={Boolean(errors.newPassword)}>{errors.newPassword}</ErrorRow>

              <FormControl mt={2}>
                <InputGroup>
                  <Input
                    name='confirmPassword'
                    layerStyle='sign-in-field'
                    type={showConfirmPassword ? 'text' : 'password'}
                    value={values.confirmPassword}
                    placeholder={translations.confirmNewPlaceholder}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    autoComplete='new-password'
                  />
                  <InputRightElement height='100%'>
                    <IconButton
                      variant='unstyled'
                      display='flex'
                      justifyContent='center'
                      alignItems='center'
                      onClick={() => setShowConfirmPassword((current) => !current)}
                      color='blackAlpha.500'
                      mr={1}
                    >
                      {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                    </IconButton>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <ErrorRow isPresent={Boolean(errors.confirmPassword)}>
                {errors.confirmPassword}
              </ErrorRow>
            </VStack>
          </VStack>
          <HStack width='100%'>
            <Button
              variant='outline'
              colorScheme='secondary_scheme'
              onClick={() => {
                setView(settingsModalView.root);
              }}
              flexBasis='50%'
            >
              {genericTranslation.back}
            </Button>
            <Button isDisabled={submitting} type='submit' flexBasis='50%'>
              {translations.update}
            </Button>
          </HStack>
        </VStack>
      </ModalBody>
    </>
  );
};

ChangePasswordFormModal.propTypes = {
  setView: PropTypes.func.isRequired,
};
