import { isFunction } from 'lodash-es';
import { handleBadStatusError, isBadStatusError } from './handleBadStatusError';
import { CustomError, handleCustomError, isCustomError } from './handleCustomError';
import { handleSilentError, isSilentError } from './handleSilentError';

export const DEFAULT_TITLE = 'Unknown Error';

/**
 *
 * @param {*} error
 * @returns {boolean}
 * @description Returns true if the argument is a direct instance of Error, and not any of its subclasses.
 */
export const isGenericError = (error) => {
  return error.constructor.name === 'Error';
};

/**
 *
 * @param {func} toast The function obtained from Chakra UI for displaying toast messages
 * @param {Error | CustomError | BadStatusError | SilentError} error The error to handle
 * @param {string} fallbackTitle Optional. A semi-specific title which can be applied to all
 * errors which may arise for a given request. Will not override titles explicitly provided to
 * CustomErrors and BadStatusErrors.
 *
 * @description Base function for handling errors. It delegates to more specialized
 * handler functions depending on the type of error passed in.
 */
export const handleError = (toast, error, fallbackTitle = DEFAULT_TITLE) => {
  if (!isFunction(toast)) {
    console.error('HEY! IT LOOKS LIKE YOU FORGOT TO PASS A TOAST FUNCTION TO YOUR ERROR HANDLER');

    if (toast instanceof Error) {
      console.error(error);
    }
    return;
  }

  if (isBadStatusError(error)) {
    handleBadStatusError(toast, error, fallbackTitle);
  } else if (isCustomError(error)) {
    handleCustomError(toast, error, fallbackTitle);
  } else if (isSilentError(error)) {
    handleSilentError();
  } else {
    handleCustomError(
      toast,
      new CustomError({
        title: fallbackTitle,
        description: error?.message,
      })
    );
  }
};
