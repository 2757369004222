import { Box, useBreakpointValue, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Outlet } from 'react-router';
import { useLocation } from 'react-router-dom';
import Footer from '~/components/footer/footer';
import { Header } from '~/components/header/header';
import Search from '~/components/search';
import { useAccountAction } from '~/hooks/account/useAccountAction';
import { useAccountState } from '~/hooks/account/useAccountState';
import { useCardNeedsReauthAlert } from '~/hooks/useCardNeedsReauthAlert';
import { useConfig } from '~/hooks/useConfig';
import { useGoogleAnalytics } from '~/hooks/useGoogleAnalytics.jsx';
import usePublisherName from '~/hooks/usePublisherName';
import { useResetPassword } from '~/hooks/useResetPassword';
import { QUERY_PARAMS, REGIONS } from '~/lib/constants';
import { goUrl } from '~/lib/helpers';
import SearchProvider from '~/lib/state/contexts/SearchProvider';
import { PAGE_CONTAINER_MAX_WIDTH, PAGE_CONTAINER_X_MARGIN } from '~/theme/constants';
import { EmailVerificationModal } from './account/email-verification-modal';
import { SignInModal } from './account/sign-in-modal';
import { SignUpModal } from './account/sign-up-modal';
import Alerts from './alerts/alerts';
import LinkBankAccountModal from './link-bank-account-modal/link-bank-account-modal';
import { ResetPasswordModal } from './modals/reset-password/reset-password-modal';
import { SettingsModalContainer } from './modals/settings/settings-modal-container';
import { TermsModal } from './terms-modal';

const brandsPageRegExp = /^\/brands(?:$|\/search$)/;

export default function PageLayout() {
  const responsive = useBreakpointValue(
    {
      base: {
        showSearchHeader: true,
      },
      md: {
        showSearchHeader: false,
      },
    },
    { fallback: 'md', ssr: false }
  );
  useGoogleAnalytics();
  const {
    publisher: { favicon },
    account: { sign_up_url },
  } = useConfig();
  const config = useConfig();
  const sso = config?.account?.sso;

  const { publisherName } = usePublisherName();

  const { pathname } = useLocation();
  useCardNeedsReauthAlert();

  const [signInIsOpen, setSignInIsOpen] = useState(false);
  const [signUpIsOpen, setSignUpIsOpen] = useState(false);
  const [settingsModalIsOpen, setSettingsModalIsOpen] = useState(false);
  const [resetPasswordModalIsOpen, setResetPasswordModalIsOpen] = useState(false);
  useResetPassword({ resetPasswordModalIsOpen, setResetPasswordModalIsOpen });

  const { emailVerificationEmail, isLoggedIn } = useAccountState();
  const { setEmailVerificationEmail, getValidToken, readSsoToken } = useAccountAction();

  useEffect(() => {
    if (isLoggedIn) {
      getValidToken();
    }
  }, [getValidToken, isLoggedIn]);

  useEffect(() => {
    if (typeof window === 'undefined' || !sso) {
      return;
    }

    const searchParams = new URLSearchParams(window.location.search);

    if (searchParams.has(QUERY_PARAMS.ONE_TIME_TOKEN)) {
      readSsoToken();
    }
  }, [readSsoToken, sso]);

  const showSearchHeader = responsive.showSearchHeader && brandsPageRegExp.test(pathname);

  return (
    <SearchProvider>
      <Helmet>
        <title>{publisherName}</title>
        {favicon ? <link rel='icon' type='image/x-icon' href={favicon} /> : ''}
      </Helmet>

      <VStack minHeight='100vh' width='100%' position='relative' spacing={0}>
        <Header
          setSettingsModalIsOpen={setSettingsModalIsOpen}
          setSignInIsOpen={setSignInIsOpen}
          setSignUpIsOpen={setSignUpIsOpen}
        />
        {showSearchHeader && (
          <Box width='100%' p={4}>
            <Search />
          </Box>
        )}
        <VStack as='article' flexGrow={1} width='inherit' spacing={0}>
          {isLoggedIn && (
            <Alerts
              width={`calc(100% - ${PAGE_CONTAINER_X_MARGIN} - ${PAGE_CONTAINER_X_MARGIN})`}
              maxWidth={PAGE_CONTAINER_MAX_WIDTH}
              mx='auto'
              my={[2, 2, 2.5, 2.5]}
              position={'relative'}
              zIndex={0}
            />
          )}
          <Outlet />
        </VStack>
        <Footer />

        {/* Modals */}
        <SignInModal
          isOpen={signInIsOpen}
          onClose={() => {
            setSignInIsOpen(false);
          }}
          openSignUp={() => {
            if (sign_up_url) {
              goUrl(sign_up_url);
            } else {
              setSignUpIsOpen(true);
            }
          }}
          onEmailVerification={(email) => {
            setEmailVerificationEmail(email);
          }}
        />
        <EmailVerificationModal
          isOpen={emailVerificationEmail !== ''}
          email={emailVerificationEmail}
          onClose={() => {
            setEmailVerificationEmail('');
          }}
        />
        <SignUpModal
          isOpen={signUpIsOpen}
          onClose={() => {
            setSignUpIsOpen(false);
          }}
          openSignIn={() => {
            setSignInIsOpen(true);
          }}
        />
        {config?.publisher?.region === REGIONS.UK && <LinkBankAccountModal />}
        <TermsModal />
        <SettingsModalContainer
          isOpen={settingsModalIsOpen}
          onClose={() => {
            setSettingsModalIsOpen(false);
          }}
        />
        <ResetPasswordModal
          isOpen={resetPasswordModalIsOpen}
          onClose={() => setResetPasswordModalIsOpen(false)}
        />
      </VStack>
    </SearchProvider>
  );
}
