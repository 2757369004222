import { res } from '~/lib/helpers';

export const textStyles = {
  bigger: { fontSize: '1.5em', m: 0, p: 0 },
  'carousel-title': {
    color: 'white',
    fontSize: ['18px', '18px', '32px', '32px'],
    textShadow: '1px 1px 1px rgba(0,0,0,1);',
  },
  'carousel-desc': {
    color: 'white',
    fontSize: ['12px', '12px', '20px', '20px'],
    noOfLines: 2,
    textShadow: '1px 1px 1px rgba(0,0,0,1);',
  },
  'contact-heading': {
    color: 'black',
    fontFamily: 'Poppins',
    fontSize: ['18px', '18px', '24px', '24px'],
    fontWeight: 600,
    pb: '10px',
    textAlign: 'center',
  },
  'contact-info': {
    color: 'secondary.primary',
    fontSize: ['14px', '14px', '16px', '16px'],
    fontWeight: 600,
    textAlign: 'center',
  },
  'contact-title': {
    color: 'white',
    fontFamily: 'Poppins',
    fontSize: ['22px', '22px', '22px', '45px'],
    fontWeight: 600,
    pb: '24px',
    textAlign: 'center',
  },
  'contact-title-success': {
    color: 'white',
    fontFamily: 'Poppins',
    fontSize: ['22px', '22px', '22px', '45px'],
    fontWeight: 600,
    pb: ['24px', '24px', '40px', '40px'],
    textAlign: 'center',
  },
  debug: {
    color: 'blackAlpha.400',
    fontSize: '12px',
  },
  'footer-terms-text': {
    color: 'secondary.primary',
    fontFamily: 'Poppins',
    fontSize: ['10px', '10px', '12px', '12px'],
    fontWeight: 500,
  },
  'footer-text': {
    color: 'secondary.secondary',
    fontFamily: 'Poppins',
    fontSize: ['12px', '12px', '16px', '16px'],
    fontWeight: 500,
  },

  'header-menu-item': {
    fontFamily: 'display',
    color: 'header_text',
    whiteSpace: 'nowrap',
  },
  'header-text': {
    fontFamily: 'display',
    fontSize: '16px',
  },
  'image-title': {
    color: 'blackAlpha.700',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '14px',
  },
  'inst-modal-name': {
    fontSize: ['14px', '14px', '16px'],
    fontWeight: [500, 500, 600],
    fontStyle: 'Roboto',
    lineHeight: '24px',
  },
  'inst-modal-title': {
    fontSize: ['20px', '20px', '28px'],
    fontWeight: 600,
    textAlign: 'left',
  },
  'landing-answer': {
    fontSize: res({ base: 14, lg: 21, xl: 28 }, true),
    ml: res({ base: 0, md: 10, lg: 20, xl: 40 }, true),
    maxWidth: '1200px',
  },
  'landing-gs-txt': {
    color: 'secondary.primary',
    fontSize: ['16px', '18px', '24px'],
    textAlign: 'center',
  },
  'landing-intro': {
    fontSize: '24px',
    lineHeight: '25px',
    fontWeight: 500,
    color: 'white',
    my: 7,
  },
  'landing-plug': {
    fontSize: res(
      {
        base: 14,
        lg: 28,
        xl: 35,
      },
      true
    ),
    lineHeight: res({ base: 16, lg: 32, xl: 50 }, true),
  },
  'landing-stat-big': {
    fontSize: ['30px', '30px', '30px', '65px'],
    fontWeight: 'bold',
    my: 0,
  },
  'lp-button': {
    color: 'white',
    fontWeight: 700,
    fontSize: '20px',
  },
  'merchant-navigation': {
    fontWeight: 600,
    fontSize: ['16px', '16px', '22px', '22px'],
    pt: '24px',
    textAlign: 'center',
  },
  minor: {
    fontSize: '12px',
    color: 'blackAlpha.600',
    fontFamily: 'display',
    textAlign: 'center',
  },
  'modal-description': {
    color: 'black',
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontWeight: 500,
    textAlign: 'center',
  },
  'modal-details-title': {
    color: '#828B97',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 400,
  },
  'modal-details-info': {
    color: '#444444',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 400,
  },
  'modal-title': {
    color: 'black',
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontWeight: 500,
    textAlign: 'center',
  },
  'preview-text': {
    color: 'secondary_color',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 400,
  },
  'sub-info': {
    alignSelf: 'flex-start',
    color: 'secondary.primary',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 400,
  },
  'store-offer': {
    fontFamily: 'display',
    fontSize: '20px',
    textAlign: 'center',
  },
  'table-v-head': {
    textAlign: 'left',
    color: 'blackAlpha.600',
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: 400,
    textTransform: 'uppercase',
  },
  'table-cell': {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
  },
  'table-card-prop-head': {
    fontSize: '12px',
    textTransform: 'uppercase',
    color: 'blackAlpha.600',
  },
  'table-card-text': {
    fontSize: '16px',
  },
};
