import { HStack, Switch, Text, useToast, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useMarketingApi } from '~/api/useMarketingApi';
import { useTranslations } from '~/hooks/useTranslations';
import { handleError } from '~/lib/errors';
import { TOAST_VARIANT } from '~/theme/default/alert-theme';

export const PromotionalEmailToggle = () => {
  const toast = useToast();
  const { genericTranslation } = useTranslations();
  const { getMarketingPreference, postMarketingPreference } = useMarketingApi();
  const [data, setData] = useState(null);

  const handleToggle = async () => {
    const categoryId = data.categories[0].category_id;
    const newSubscribedValue = !data.categories[0].subscribed;
    const body = {};
    body.categories = { [categoryId]: newSubscribedValue };
    try {
      await postMarketingPreference(body);
      const newData = { ...data };
      newData.categories[0].subscribed = newSubscribedValue;
      setData(newData);

      toast({
        title: genericTranslation.settings.emailPreferences.success,
        variant: TOAST_VARIANT.SUCCESS,
        status: TOAST_VARIANT.SUCCESS,
      });
    } catch (error) {
      handleError(toast, error, 'Could not update marketing preference');
    }
  };

  useEffect(() => {
    const fetchUserMarketingPreference = async () => {
      try {
        const data = await getMarketingPreference();

        setData(data);
      } catch (error) {
        handleError(toast, error, 'Could not get marketing preference');
      }
    };

    fetchUserMarketingPreference();
  }, [getMarketingPreference, toast]);

  const isLoading = !data;

  const isMarketingPrefToggled = data?.categories[0].subscribed;

  return (
    <VStack gap={2} alignItems='flex-start' width='100%'>
      <Text color='secondary.primary' fontWeight='medium' fontFamily='display' fontSize='sm'>
        {genericTranslation.settings.emailPreferences.header}
      </Text>
      <HStack
        width='100%'
        justifyContent='space-between'
        borderWidth='1px'
        borderColor='secondary.tertiary'
        borderRadius='xl'
        height={16}
        paddingX={4}
        paddingY={6}
      >
        <Text fontFamily='display' fontWeight='medium' fontSize='18px' color='neutral.primary'>
          {genericTranslation.settings.emailPreferences.toggleLabel}
        </Text>
        {isLoading ? null : <Switch isChecked={isMarketingPrefToggled} onChange={handleToggle} />}
      </HStack>
    </VStack>
  );
};
