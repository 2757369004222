import { Button, Image, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { useFinance } from '~/hooks/useFinance';
import { useTranslations } from '~/hooks/useTranslations';
import { ModalLayout } from '../modal-layout';
import { RR_STEPS } from './rewards-registration-modal';

export const RewardsRegistrationIntro = ({ setStep }) => {
  const { cards, linkCards, isLoading } = useFinance();
  const { financeTranslation } = useTranslations();
  const { intro } = financeTranslation.rewardsRegistrationModal;
  const hasLinkedCards = cards?.length > 0;

  const handleClick = () => {
    if (hasLinkedCards) {
      setStep(RR_STEPS.ZIP_CODE);
    } else {
      linkCards(undefined, () => {
        setStep(RR_STEPS.ZIP_CODE);
      });
    }
  };

  return (
    <ModalLayout
      header={
        <>
          <Image src='/img/fork-and-knife.svg' height='70px' />
          {intro.header}
        </>
      }
      body={intro.body.map((t) => (
        <Text textAlign='center' maxWidth='22rem' key={t}>
          {t}
        </Text>
      ))}
      footer={
        <Button onClick={handleClick} isLoading={isLoading}>
          {hasLinkedCards ? intro.linked_cta : intro.not_linked_cta}
        </Button>
      }
    />
  );
};

RewardsRegistrationIntro.propTypes = {
  setStep: PropTypes.func.isRequired,
};
