import { Modal, ModalContent, ModalOverlay } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useFinance } from '~/hooks/useFinance';
import { RewardsRegistrationIntro } from './rewards-registration-intro';
import { RewardsRegistrationZipCode } from './rewards-registration-zip-code';

export const RR_STEPS = {
  INTRO: 'INTRO',
  ZIP_CODE: 'ZIP_CODE',
  CARDS: 'CARDS',
  OUTRO: 'OUTRO',
  EXIT: 'EXIT',
};

export const RewardsRegistrationModal = ({ onClose }) => {
  const [step, setStep] = useState(RR_STEPS.INTRO);
  const { plaidModalIsOpen } = useFinance();

  return (
    <Modal isOpen isCentered onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        maxHeight='min(95vh, 50rem)'
        opacity={plaidModalIsOpen ? '0 !important' : 1}
        overflow='hidden'
        pointerEvents={plaidModalIsOpen ? 'none' : 'auto'}
        px={10}
        py={8}
      >
        {step === RR_STEPS.INTRO && <RewardsRegistrationIntro setStep={setStep} />}
        {step === RR_STEPS.ZIP_CODE && <RewardsRegistrationZipCode setStep={setStep} />}
      </ModalContent>
    </Modal>
  );
};

RewardsRegistrationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};
