import {
  Box,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Spinner,
  Text,
  useTheme,
  useToast,
} from '@chakra-ui/react';
import { debounce } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FaChevronRight } from 'react-icons/fa6';
import { useUkInstitutionsApi } from '~/api/useUkInstitutionsApi';
import { useAccountState } from '~/hooks/account/useAccountState';
import { useTranslations } from '~/hooks/useTranslations';
import { useUkFinance } from '~/hooks/useUkFinance';
import { CustomError, handleError } from '~/lib/errors';
import { logEvent } from '~/lib/logEvent';

const DEBOUNCE_MS = 300;
function makeSearchParam(searchParams) {
  return `searchText=${encodeURIComponent(searchParams)}`;
}

export default function LinkBankAccountModal() {
  const toast = useToast();
  const { isLoggedIn } = useAccountState();
  const theme = useTheme();
  const { financeTranslation } = useTranslations();
  const { getGoCardlessInstitutions, getRedirectUrl } = useUkInstitutionsApi();
  const { isGcModalOpen, setIsGcModalOpen } = useUkFinance();

  const [searchText, setSearchText] = useState('');
  const [institutions, setInstitutions] = useState([]);
  const [isLoadingInstitutions, setIsLoadingInstitutions] = useState(false);

  const debouncedLoadInstitutions = useMemo(
    () =>
      debounce(
        async (search) => {
          setIsLoadingInstitutions(true);

          try {
            const searchPhrase = search ? makeSearchParam(search) : '';

            const data = await getGoCardlessInstitutions(searchPhrase);

            if (!Array.isArray(data)) {
              throw new CustomError({
                title: 'Bad data for institutions',
                description: 'Cannot find institutions',
              });
            }

            setInstitutions(data);
          } catch (error) {
            handleError(toast, error, 'Error fetching institutions');
          } finally {
            setIsLoadingInstitutions(false);
          }
        },
        DEBOUNCE_MS,
        { leading: true, trailing: true }
      ),
    [getGoCardlessInstitutions, toast]
  );

  const handleInstitutionClick = useCallback(
    async (institution) => {
      if (institution) {
        logEvent('GoCardLess', { action: 'link_card' });

        try {
          const data = await getRedirectUrl(institution.id);

          if (data?.link_card_redirect_url) {
            window.location.href = data.link_card_redirect_url;
          } else {
            throw new Error('Failed to fetch valid GoCardless URL');
          }
        } catch (err) {
          handleError(toast, err, financeTranslation.cannotLink);
        }
      }
    },
    [getRedirectUrl, toast, financeTranslation.cannotLink]
  );

  useEffect(() => {
    if (isLoggedIn) {
      debouncedLoadInstitutions(searchText);
    }
  }, [isLoggedIn, debouncedLoadInstitutions, searchText]);

  return (
    <Modal isOpen={isGcModalOpen} onClose={() => setIsGcModalOpen(false)} isCentered>
      <ModalOverlay />
      <ModalContent
        height={['640px', '640px', '780px']}
        maxHeight='75%'
        width={['360px', '360px', '672px']}
        maxWidth='80%'
        px='60px'
        py='60px'
        display='flex'
        gap={4}
        flexDir='column'
      >
        <ModalHeader flex='0 0 auto' width='100%' padding={0}>
          <Text textStyle='inst-modal-title'>Select Your Account</Text>
        </ModalHeader>
        <ModalCloseButton />
        <Box position='sticky' top={0} flex='0 0 auto' width='100%' backgroundColor='surface.white'>
          <InputGroup variant='searchInst'>
            <InputLeftAddon backgroundColor='white'>
              <img alt='search icon' src='/icons/text-search.svg' />
            </InputLeftAddon>
            <Input
              name='searchText'
              borderTopLeftRadius={0}
              borderBottomLeftRadius={0}
              placeholder='Search for an institution'
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              value={searchText}
            />
          </InputGroup>
        </Box>
        {isLoadingInstitutions ? (
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            flex='1 1 auto'
            width='100%'
          >
            <Spinner />
          </Box>
        ) : (
          <SimpleGrid
            flex='1 1 auto'
            columns={[1, 1, 2]}
            spacingY='10px'
            spacingX='10px'
            overflowY='scroll'
            width='100%'
            gridAutoRows={['55px', '55px', '85px']}
          >
            {(institutions ?? []).map((institution) => (
              <Box
                key={institution?.id}
                sx={{
                  border: '1px solid #D9DBDF',
                  borderRadius: '12px',
                  cursor: 'pointer',
                  height: ['55px', '55px', '85px'],
                  _hover: {
                    backgroundColor: 'brand-tertiary',
                  },
                  padding: ['10px 20px 10px 20px', '10px 20px 10px 20px', '20px'],
                  width: ['100%', '100%', undefined],
                  maxWidth: ['290px', '290px', '265px'],
                  margin: ['auto', 'auto', undefined],
                }}
                onClick={() => handleInstitutionClick(institution)}
              >
                <HStack>
                  {institution.logo && (
                    <Image
                      alt='logo'
                      src={institution.logo}
                      width={['35px', '35px', '45px']}
                      height={['35px', '35px', '45px']}
                    />
                  )}
                  <Text noOfLines={[1, 1, 2]} textStyle='inst-modal-name'>
                    {institution.name}
                  </Text>
                  <FaChevronRight
                    color={theme.colors.secondary.primary}
                    style={{ marginLeft: 'auto' }}
                  />
                </HStack>
              </Box>
            ))}
          </SimpleGrid>
        )}
      </ModalContent>
    </Modal>
  );
}
