import {
  Button,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { FaLocationDot } from 'react-icons/fa6';
import * as Yup from 'yup';
import { useAccountAction } from '~/hooks/account/useAccountAction';
import { useAccountState } from '~/hooks/account/useAccountState';
import { useTranslations } from '~/hooks/useTranslations';
import { useValidationSchema } from '~/hooks/useValidationSchema';
import { ModalLayout } from '../modal-layout';
import { RR_STEPS } from './rewards-registration-modal';

const ZIP_CODE_FIELD = 'ZIP_CODE';

export const RewardsRegistrationZipCode = ({ setStep }) => {
  const { zipCodeValidation } = useValidationSchema();
  const { financeTranslation } = useTranslations();
  const { zipCode } = financeTranslation.rewardsRegistrationModal;
  const { userZipCode } = useAccountState();
  const { updateZipCode } = useAccountAction();

  const initialValues = { [ZIP_CODE_FIELD]: userZipCode ?? '' };

  const onSubmit = async (values) => {
    await updateZipCode(values[ZIP_CODE_FIELD]);
    setStep(RR_STEPS.CARDS);
  };

  const validationSchema = Yup.object().shape({
    [ZIP_CODE_FIELD]: zipCodeValidation,
  });

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const isInvalid = errors[ZIP_CODE_FIELD] && touched[ZIP_CODE_FIELD];

  return (
    <ModalLayout
      header={zipCode.header}
      body={
        <>
          {zipCode.body.map((t) => (
            <Text textAlign='center' key={t}>
              {t}
            </Text>
          ))}

          <FormControl isInvalid={isInvalid}>
            <InputGroup>
              <InputLeftElement height='100%' width='auto' mx={4} color='secondary.primary'>
                <FaLocationDot color='currentcolor' />
              </InputLeftElement>
              <Input
                pl='42px'
                name={ZIP_CODE_FIELD}
                borderRadius='lg'
                height={14}
                placeholder={zipCode.placeholder}
                value={values[ZIP_CODE_FIELD]}
                onChange={handleChange}
                onBlur={handleBlur}
                borderColor='secondary.secondary'
                _focusVisible={{ boxShadow: 'none', borderColor: 'brand.primary' }}
                _focus={{ boxShadow: 'none', borderColor: 'brand.primary' }}
              />
            </InputGroup>
            <FormErrorMessage>{errors[ZIP_CODE_FIELD]}</FormErrorMessage>
          </FormControl>
        </>
      }
      footer={
        <Button width='17rem' onClick={handleSubmit} isDisabled={isInvalid}>
          {zipCode.cta}
        </Button>
      }
    />
  );
};

RewardsRegistrationZipCode.propTypes = {
  setStep: PropTypes.func.isRequired,
};
