import { useToast } from '@chakra-ui/react';
import * as PropTypes from 'prop-types';
import { createContext, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useInstitutionsApi } from '~/api/useInstitutionsApi';
import { useAccountState } from '~/hooks/account/useAccountState';
import { useFetchUrl } from '~/hooks/useFetchUrl';
import { useTranslations } from '~/hooks/useTranslations';
import { CARD_ACCESS_TOKEN_URL, CARDS_API_URL } from '~/lib/constants';
import { CustomError, handleError } from '~/lib/errors';
import { currentTimeInMS, neutralizeEvent, plural } from '~/lib/helpers';
import { logEvent } from '~/lib/logEvent';
import { TOAST_VARIANT } from '~/theme/default/alert-theme';

const CARD_IMAGE_URL_ACTIVE = '/img/bank-card-active.svg';
const CARD_IMAGE_URL_INACTIVE = '/img/bank-card-inactive.svg';

/**
 * @typedef {Object} Cards
 * @property {string} cardNumber
 * @property {string} cardType
 */

/**
 * @typedef {Object} Institution
 * @property {string} institution_id
 * @property {string} institution_name
 * @property {string} color
 */

export const FinanceContext = createContext();

// importing useTranslations does not work here

const FinanceProvider = ({ children }) => {
  const fetchUrl = useFetchUrl();
  const toast = useToast();
  const { financeTranslation } = useTranslations();
  const { isLoggedIn, accessTokenExpiration } = useAccountState();
  const isExpired = !accessTokenExpiration || accessTokenExpiration < currentTimeInMS();
  const { fetchCards, fetchLinkToken, setToken } = useInstitutionsApi();
  const [isLoading, setIsLoading] = useState(false);
  const [institutions, setInstitutions] = useState([]);
  const [plaidModalIsOpen, setPlaidModalIsOpen] = useState(false);
  const hasLoaded = useRef(false);

  const cards = useMemo(
    () => institutions?.map((inst) => inst.linked_cards).flat() ?? [],
    [institutions]
  );

  const statusText = useMemo(() => {
    const cardCount = cards?.length;
    const institutionCount = institutions?.length;

    const firstInst = institutions?.[0];

    if (!cardCount) {
      return financeTranslation.noCards;
    }

    // Too complicated to translate
    return `You have linked ${plural(
      {
        single: 'one card',
        two: 'two cards',
        many: `${cardCount} cards`,
      },
      cardCount
    )}
      from ${plural(
        {
          single: firstInst?.institution_name ?? 'one bank',
          two: 'two banks',
          many: `${institutionCount} banks`,
        },
        institutionCount
      )}. 
      You can link ${plural(
        {
          single: 'a second card',
          many: 'another card',
        },
        cardCount
      )} to earn more cash. `;
  }, [cards, institutions, financeTranslation]);

  const cardImageUrl = useMemo(
    () => (cards?.length > 0 ? CARD_IMAGE_URL_ACTIVE : CARD_IMAGE_URL_INACTIVE),
    [cards]
  );

  const refetchInstitutions = useCallback(async () => {
    setIsLoading(true);

    try {
      const data = await fetchCards();

      if (!Array.isArray(data?.linked_institutions)) {
        throw new CustomError({
          title: financeTranslation.linkedInstitutionsErrorTitle,
          description: financeTranslation.linkedInstitutionsErrorDescription,
        });
      }

      setInstitutions(data.linked_institutions);
    } catch (error) {
      handleError(toast, error, financeTranslation.errorFetchingCards);
    } finally {
      setIsLoading(false);
    }
  }, [fetchCards, financeTranslation, toast]);

  const reAuth = useCallback(
    async (instId, { onSuccess, onExit } = {}, e) => {
      if (e) {
        neutralizeEvent(e);
      }
      logEvent('plaid', { action: 'link_card' });

      if (isExpired) {
        toast({
          title: financeTranslation.cannotLink,
          description: `${financeTranslation.sessionExpiredTitle}. ${financeTranslation.sessionExpiredDescription}.`,
          status: TOAST_VARIANT.ERROR,
        });
        return;
      }

      try {
        const response = await fetchUrl(`${CARDS_API_URL}auth-token/${instId}`);

        const info = await response.json();

        const linkTokenPresent = info && typeof info === 'object' && 'link_token' in info;
        if (!linkTokenPresent) {
          throw new CustomError({
            title: financeTranslation.reauthorizationFailed,
            description: financeTranslation.noPlaidLinkToken,
          });
        }

        setPlaidModalIsOpen(true);
        // Plaid is a global library
        // eslint-disable-next-line no-undef
        Plaid.create({
          token: info.link_token,
          onSuccess: async () => {
            logEvent('plaid', { action: 'link_success' });
            await refetchInstitutions();
            setPlaidModalIsOpen(false);
            onSuccess?.();
          },
          onExit() {
            setPlaidModalIsOpen(false);
            onExit?.();
          },
        }).open();
      } catch (err) {
        toast({
          title: financeTranslation.cannotLink,
          description: err.message,
          status: TOAST_VARIANT.ERROR,
        });
      }
    },
    [isExpired, refetchInstitutions, financeTranslation, fetchUrl, toast]
  );

  const afterLink = useCallback(
    async ([public_token, metadata], onLink) => {
      const { institution } = metadata;
      onLink?.();
      setPlaidModalIsOpen(false);

      try {
        await setToken(public_token, institution);

        toast({
          title: financeTranslation.cardLinked,
          description: financeTranslation.cardLinkedFromX(institution.name),
          variant: TOAST_VARIANT.SUCCESS,
          status: TOAST_VARIANT.SUCCESS,
        });
        return await refetchInstitutions();
      } catch (error) {
        handleError(toast, error, financeTranslation.cardNotLinked);
      }
    },
    [setToken, refetchInstitutions, financeTranslation, toast]
  );

  const linkCards = useCallback(
    async (e, onLink) => {
      if (e) {
        e.preventDefault();
      }
      logEvent('plaid', { action: 'link_card' });

      try {
        if (isExpired) {
          throw new CustomError({
            title: financeTranslation.sessionExpiredTitle,
            description: financeTranslation.sessionExpiredDescription,
          });
        }

        const data = await fetchLinkToken();
        const linkTokenPresent = data && typeof data === 'object' && 'link_token' in data;
        if (!linkTokenPresent) {
          throw new CustomError({
            title: financeTranslation.cannotLink,
            description: financeTranslation.noPlaidLinkToken,
          });
        }

        setPlaidModalIsOpen(true);
        // Plaid is a global library
        // eslint-disable-next-line no-undef
        Plaid.create({
          token: data.link_token,
          onSuccess: (...args) => {
            logEvent('plaid', { action: 'link_success' });
            afterLink(args, onLink);
          },
          onExit() {
            setPlaidModalIsOpen(false);
          },
        }).open();
      } catch (error) {
        handleError(toast, error, financeTranslation.linkingCardErrorTitle);
      }
    },
    [fetchLinkToken, afterLink, financeTranslation, isExpired, toast]
  );

  const unlinkCard = useCallback(
    async (inst, instId) => {
      try {
        await fetchUrl(CARD_ACCESS_TOKEN_URL + instId, { method: 'DELETE' });

        const description = inst?.institution_name
          ? 'Your card for ' +
            inst?.institution_name +
            ' is no longer being tracked by our rewards service'
          : 'Your card is no longer being tracked by our rewards service';

        toast({
          title: financeTranslation.cardUnlinked,
          variant: TOAST_VARIANT.SUCCESS,
          status: TOAST_VARIANT.SUCCESS,
          description,
        });

        return refetchInstitutions();
      } catch (error) {
        handleError(toast, error, financeTranslation.cannotUnlink);
      }
    },
    [refetchInstitutions, financeTranslation, fetchUrl, toast]
  );

  useEffect(() => {
    if (isLoggedIn && !hasLoaded.current) {
      refetchInstitutions();
      hasLoaded.current = true;
    } else if (!isLoggedIn) {
      hasLoaded.current = false;
      setInstitutions([]);
    }
  }, [isLoggedIn, refetchInstitutions]);

  const values = useMemo(
    () => ({
      cards,
      cardImageUrl,
      institutions,
      refetchInstitutions,
      reAuth,
      plaidModalIsOpen,
      linkCards,
      unlinkCard,
      isLoading,
      statusText,
    }),
    [
      cards,
      cardImageUrl,
      institutions,
      refetchInstitutions,
      plaidModalIsOpen,
      linkCards,
      unlinkCard,
      reAuth,
      isLoading,
      statusText,
    ]
  );
  return <FinanceContext.Provider value={values}>{children}</FinanceContext.Provider>;
};

export default FinanceProvider;

FinanceProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
