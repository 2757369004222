import { useCallback, useMemo } from 'react';
import * as Yup from 'yup';
import { COMMON_PASSWORDS, MIN_CHARS } from '~/lib/constants';
import { EMAIL_RE, SPECIAL_RE } from '~/lib/regexp';
import { useTranslations } from './useTranslations';

export const useValidationSchema = () => {
  const { genericTranslation } = useTranslations();

  const emailValidation = useMemo(
    () =>
      Yup.string()
        .required(genericTranslation.validation.emailRequired)
        .test('email-valid', genericTranslation.validation.email, (value) => EMAIL_RE.test(value))
        .email(),
    [genericTranslation.validation]
  );

  const passwordValidation = useMemo(
    () =>
      Yup.string()
        .required(genericTranslation.validation.passwordRequired)
        .test(
          'password-has-lower-and-upper-case',
          genericTranslation.validation.passwordCase,
          (value) => /[a-z]/.test(value) && /[A-Z]/.test(value)
        )
        .test(
          'password-has-special-symbol',
          genericTranslation.validation.passwordSymbol,
          (value) => SPECIAL_RE.test(value)
        )
        .test('password-has-one-number', genericTranslation.validation.passwordNumber, (value) =>
          /\d/.test(value)
        )
        .test(
          'password-is-long-enough',
          genericTranslation.validation.passwordIsNotLongEnough(MIN_CHARS),
          (value) => value && value.length >= MIN_CHARS
        )
        .test(
          'password-is-uncommon',
          genericTranslation.validation.passwordIsTooCommon,
          (value) => value && !COMMON_PASSWORDS.includes(value.toLowerCase())
        ),
    [genericTranslation.validation]
  );

  const confirmPasswordValidation = useCallback(
    (passwordFieldName = 'password') =>
      Yup.string()
        .required(genericTranslation.validation.passwordMatch)
        .oneOf([Yup.ref(passwordFieldName), null], genericTranslation.validation.passwordMatch),
    [genericTranslation.validation.passwordMatch]
  );

  const zipCodeValidation = useMemo(
    () =>
      Yup.string()
        .required(genericTranslation.validation.zipCodeRequired)
        .matches(/^\d{5}$/, genericTranslation.validation.zipCode),
    [genericTranslation.validation]
  );

  return { emailValidation, passwordValidation, confirmPasswordValidation, zipCodeValidation };
};
