import {
  Box,
  Circle,
  CircularProgress,
  Grid,
  GridItem,
  Heading,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import { PageContainer } from '~/components/page-container';
import { useFetchUrl } from '~/hooks/useFetchUrl';
import usePublisherName from '~/hooks/usePublisherName';
import { useTranslations } from '~/hooks/useTranslations';
import { BRANDS_API_URL, BRANDS_NOT_FOUND_ROUTE, SORT_BY, SORT_DIRECTION } from '~/lib/constants';
import { handleError, isBadStatusError } from '~/lib/errors';
import imgUrl from '~/lib/helpers';
import { TOAST_VARIANT } from '~/theme/default/alert-theme';
import { OfferList } from './offer-list';
import { RelatedOffersSection } from './related-offers-section';

const NUVEI_STATUS_PARAM = 'ppp_status';

export function BrandPage() {
  const fetchUrl = useFetchUrl();
  const { brandUid } = useParams();
  const navigate = useNavigate();
  const toast = useToast();
  const toastShownRef = useRef(false);
  const { brandsTranslation } = useTranslations();

  const [brand, setBrand] = useState(null);
  const [relatedOffers, setRelatedOffers] = useState([]);
  const [brandLoading, setBrandLoading] = useState(true);
  const [offersLoading, setOffersLoading] = useState(true);
  const [brandError, setBrandError] = useState(null);

  const { publisherName } = usePublisherName();

  const responsive = useBreakpointValue({
    base: {
      cols: '1fr',
      rows: 'auto auto',
      template: '"brand" "related-offers"',
    },
    lg: {
      cols: '1fr 24rem',
      template: '"brand related-offers"',
      rows: 'auto',
    },
  });

  const filteredRelatedOffers = useMemo(() => {
    return relatedOffers.filter((offer) => offer.brand_uid !== brand?.brand_uid);
  }, [relatedOffers, brand]);

  const fetchBrand = useCallback(async () => {
    const res = await fetchUrl(`${BRANDS_API_URL}/${brandUid}`);
    return await res.json();
  }, [brandUid, fetchUrl]);

  const fetchRelatedOffers = useCallback(
    async (category_id) => {
      try {
        const searchParams = new URLSearchParams({
          category_id,
          sort: [SORT_BY.OFFER_RANK, SORT_DIRECTION.ASC],
          size: 6,
        });
        const offerEndpoint = `${BRANDS_API_URL}?${searchParams.toString()}`;
        const offersRes = await fetchUrl(offerEndpoint);
        const { content } = await offersRes.json();
        return content;
      } catch (error) {
        handleError(toast, error, 'Could not load related offers');
        return [];
      }
    },
    [fetchUrl, toast]
  );

  const loadBrand = useCallback(async () => {
    try {
      setBrandLoading(true);
      setOffersLoading(true);

      const brandData = await fetchBrand();
      setBrand(brandData);

      const relatedOffers = await fetchRelatedOffers(brandData.category_id);
      setRelatedOffers(relatedOffers);
    } catch (err) {
      if (isBadStatusError(err)) {
        // always navigate to not found page if there is an error, user doesn't need to know the details
        navigate(BRANDS_NOT_FOUND_ROUTE);
        return;
      }

      setBrandError(err);
    } finally {
      setBrandLoading(false);
      setOffersLoading(false);
    }
  }, [fetchBrand, fetchRelatedOffers, navigate]);

  // load brand
  useEffect(() => {
    if (brandUid) {
      loadBrand();
    } else {
      setBrandLoading(false);
      setOffersLoading(false);
    }
  }, [brandUid, loadBrand]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get(NUVEI_STATUS_PARAM) === 'FAIL' && !toastShownRef.current) {
      toast({
        title: 'Oops! Looks like something went wrong with your payment. Please try again.',
        variant: TOAST_VARIANT.ERROR,
        status: TOAST_VARIANT.ERROR,
        position: 'top',
      });
      toastShownRef.current = true;
    }
  }, [brand, toast]);

  let offerListHeading = brand?.name;
  if (relatedOffers.length > 0) {
    offerListHeading += ` ${
      brandsTranslation.offerListHeading[relatedOffers.length === 1 ? 'single' : 'plural']
    }`;
  }

  /** Render errorElement by throwing brandError */
  if (brandError) {
    throw brandError;
  }

  const isLoading = brandLoading || offersLoading;

  return (
    <Fragment>
      {brand?.name && (
        <Helmet>
          <title>
            {brand.name} | {publisherName}
          </title>
        </Helmet>
      )}
      <PageContainer maxWidth='1400px'>
        {isLoading && <CircularProgress size={8} isIndeterminate color='brand.primary' />}
        {Boolean(!isLoading && brand) && (
          <Grid
            templateColumns={responsive.cols}
            templateRows={responsive.rows}
            templateAreas={responsive.template}
            gridColumnGap={10}
            width='100%'
            justifyContent='center'
          >
            <GridItem
              name='brand'
              border='1px solid'
              borderColor='secondary.200'
              borderRadius='3xl'
              overflow='hidden'
            >
              <Box layerStyle='brand-page-banner' backgroundImage={imgUrl(brand.large_img_url)} />
              <Box layerStyle='brand-page-logo-wrapper'>
                <Circle
                  layerStyle='brand-page-logo'
                  backgroundColor='white'
                  backgroundImage={imgUrl(brand.logo_img_url)}
                />
              </Box>
              <Heading variant='page-head'>{offerListHeading}</Heading>

              <OfferList offers={brand.offers ?? []} brand={brand} />
            </GridItem>

            <RelatedOffersSection offers={filteredRelatedOffers} />
          </Grid>
        )}
      </PageContainer>
    </Fragment>
  );
}
