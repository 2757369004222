import PropTypes from 'prop-types';
import { createContext, useCallback, useMemo, useState } from 'react';
import { RewardsRegistrationModal } from '~/components/modals/rewards-registration/rewards-registration-modal';
import { MODALS } from '~/lib/constants';

export const ModalActionContext = createContext(null);
export const ModalStateContext = createContext(null);

export const ModalProvider = ({ children }) => {
  const [modalName, setModalName] = useState(null);

  const openModal = useCallback(
    (name) => {
      setModalName(name);
    },
    [setModalName]
  );

  const closeModal = useCallback(() => {
    setModalName(null);
  }, [setModalName]);

  const modal = useMemo(() => {
    switch (modalName) {
      case MODALS.REWARDS_REGISTRATION:
        return <RewardsRegistrationModal onClose={closeModal} />;
      default:
        return null;
    }
  }, [modalName, closeModal]);

  const modalStateValues = useMemo(
    () => ({
      isModalOpen: (name) => name === modalName,
    }),
    [modalName]
  );

  const modalActionValues = useMemo(() => {
    return {
      openModal,
      closeModal,
    };
  }, [openModal, closeModal]);

  return (
    <ModalActionContext.Provider value={modalActionValues}>
      <ModalStateContext.Provider value={modalStateValues}>
        {children}
        {modal}
      </ModalStateContext.Provider>
    </ModalActionContext.Provider>
  );
};

ModalProvider.propTypes = { children: PropTypes.node.isRequired };
